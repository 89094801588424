import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { navigate } from "gatsby"

import { login, isAuthenticated} from "../utils/auth"


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    backgroundColor:'gray',
    height: '15vh'
  },
  paper1: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    backgroundColor:'gray',
    height: '30vh'
  },
}));

export default function Developper() {
  const classes = useStyles();

  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }else{
  return (
  <Container >
    <Box borderTop={1} borderLeft={1}  >
        <h1 style={{textAlign: 'center'}}> Developper ?</h1>
    </Box>
    <Box style={{marginTop: '10vh'}}>
    <Grid container spacing={2}direction='row-reverse'>
        <Grid item xs={4}  >
          <Paper className={classes.paper1} onClick={()=>(navigate('/'))}>
            <p>Part1</p>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper className={classes.paper1} onClick={()=>(navigate('/'))}>
            <p>Part2</p>
          </Paper>
        </Grid>
        <Grid item xs= {6}>
          <Paper className={classes.paper} onClick={()=>(navigate('/'))}>
            <p>Part3</p>
          </Paper>
        </Grid> 
        <Grid item xs= {6}>
          <Paper className={classes.paper} onClick={()=>(navigate('/'))}>
            <p>Part4</p>
          </Paper>
        </Grid> 
        <Grid item xs>
          <Paper className={classes.paper} onClick={()=>(navigate('/'))}>
            <p>...part5 </p>
          </Paper>
        </Grid> 
    </Grid>
    </Box>
  </Container>
  );
}
}